import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
    const [recaptchaValue, setRecaptchaValue] = useState('');

    const handleRecaptchaChange = (value: any) => {
        setRecaptchaValue(value);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
    
        const formData = {
            submissionType: 'Contact',
            name: event.target.elements.name.value,
            address: event.target.elements.address.value,
            email: event.target.elements.email.value,
            phone: event.target.elements.phone.value,
            inquiryType: event.target.elements.inquiryType.value,
            comment: event.target.elements.comment.value,
            recaptchaValue: recaptchaValue
        };
    
        try {
            const response = await fetch('/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            const result = await response.json();
            if (response.ok) {
                alert('Thank you for your inquiry. We will get back to you as soon as possible.');
                console.log('Form submitted:', result);
                event.target.reset();  // Reset the form fields
                setRecaptchaValue(''); // Reset the ReCAPTCHA
            } else {
                alert('There was an error submitting your inquiry. Please try again later.');
                console.log('Form submission failed:', result);
                event.target.reset();  // Reset the form fields
                setRecaptchaValue(''); // Reset the ReCAPTCHA
            }
        } catch (error) {
            alert('There was an error submitting your inquiry. Please try again later.');
            console.error('Error submitting form:', error);
            event.target.reset();  // Reset the form fields
            setRecaptchaValue(''); // Reset the ReCAPTCHA
        }
    };     

    return (
        <div className='card'>
            <div className='container' style={{display: 'flex'}}>
                <div className='container'>
                    <h1>Contact Us</h1>
                    <h2>We Would Love To Hear From You</h2>
                    <p>Atlanta Executive Property Management<br />
                    101 Devant Street<br />
                    Unit 501<br />
                    Fayetteville, GA 30214<br />
                    Phone: (678) 235-2270<br />
                    Fax: (678) 235-2285<br />
                    Email: info@atlexecpm.com</p>
                    
                    <h3>Office Hours</h3>
                    <p>Monday - Friday: 9:00am – 5:00pm<br />
                    Saturday - Sunday: Closed</p>
                    <b style={{fontStyle: 'italic'}}>Office Visits are by appointment only.</b>
                </div>

                <form onSubmit={handleSubmit}>
                    <h2>Drop Us A Line!</h2>
                    <input type="text" name="name" placeholder="Name" required />
                    <input type="text" name="address" placeholder="Address" />
                    <input type="email" name="email" placeholder="Email" required />
                    <input type="tel" name="phone" placeholder="Phone" />
                    <select name="inquiryType">
                        <option value="generalInquiry">General Inquiry</option>
                        <option value="vendorRequest">Vendor Request</option>
                        <option value="maintenanceRequest">Maintenance Request</option>
                        <option value="clubhouseAvailability">Clubhouse Availability</option>
                        <option value="associationManagement">Association Management</option>
                        <option value="singleFamilyRental">Single Family/Short Term Rental Management</option>
                        <option value="other">Other</option>
                    </select>
                    <textarea name="comment" placeholder="Comment"></textarea>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        onChange={handleRecaptchaChange}
                        style={{marginTop: '20px'}}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>

            <div style={{ marginTop: '20px' }}>
                <iframe
                    title="AEPM Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.1240313981914!2d-84.46059068523357!3d33.45039998076726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4e414d761c8bf%3A0x748b0b62a8c22f07!2s101%20Devant%20St%20Unit%20501%2C%20Fayetteville%2C%20GA%2030214%2C%20USA!5e0!3m2!1sen!2sin!4v1655210609949!5m2!1sen!2sin"
                    style={{ border: 0, width: '100%', height: '450px' }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
