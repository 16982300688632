import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';

const getActiveClassName = ({ isActive }: { isActive: boolean }) => isActive ? 'active' : '';

const Header: React.FC = () => {
  const [isLinksDropdownOpen, setLinksDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isHomeownersDropdownOpen, setHomeownersDropdownOpen] = useState(false);
  const [isClientsDropdownOpen, setClientsDropdownOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleLinksDropdown = () => {
    setLinksDropdownOpen(!isLinksDropdownOpen);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  }

  const toggleHomeownersDropdown = () => {
    setHomeownersDropdownOpen(!isHomeownersDropdownOpen);
  }

  const toggleClientsDropdown = () => {
    setClientsDropdownOpen(!isClientsDropdownOpen);
  }

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Close dropdown if clicked outside
  const closeDropdown = (event: MouseEvent) => {
    if (!(event.target as Element).closest('.links-dropdown'))
      setLinksDropdownOpen(false);
    if (!(event.target as Element).closest('.services-dropdown'))
      setServicesDropdownOpen(false);
    if (!(event.target as Element).closest('.homeowners-dropdown'))
      setHomeownersDropdownOpen(false);
    if (!(event.target as Element).closest('.clients-dropdown'))
      setClientsDropdownOpen(false);
  };

  // Add event listener to close dropdown when clicked outside
  React.useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  // Prevent dropdown from closing when clicking on external link
  const handleExternalLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  };

  // Prevent dropdown from closing when clicking on dropdown menu
  const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <nav className="header">
      <div className="hamburger" onClick={toggleNav}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className={`nav-links ${isNavOpen ? 'active' : ''}`}>
        <NavLink to="/" className={getActiveClassName} end>Home</NavLink>
        <div className={getActiveClassName + " links-dropdown"} onMouseEnter={toggleServicesDropdown} onMouseLeave={toggleServicesDropdown} onClick={handleDropdownClick}>
          <NavLink to="/services">Services ▾</NavLink>
          {isServicesDropdownOpen && (
            <div className="dropdown-content">
              <NavLink to="/services/community-management" className={getActiveClassName}>Community Management</NavLink>
              <NavLink to="/services/financial-services" className={getActiveClassName}>Financial Services</NavLink>
              <NavLink to="/services/real-estate-services" className={getActiveClassName}>Real Estate Services</NavLink>
            </div>
          )}
        </div>
        <NavLink to="/about-us" className={getActiveClassName}>About Us</NavLink>
        <NavLink to="/request-a-proposal" className={getActiveClassName}>Request A Proposal</NavLink>
        <NavLink to="/rental" className={getActiveClassName}>Rental</NavLink>
        <NavLink to="/contact" className={getActiveClassName}>Contact Us</NavLink>
        <div className="links-dropdown" onMouseEnter={toggleLinksDropdown} onMouseLeave={toggleLinksDropdown}>
          Links ▾
          {isLinksDropdownOpen && (
            <div className="dropdown-content">
              <div className="links-dropdown" onMouseEnter={toggleHomeownersDropdown} onMouseLeave={toggleHomeownersDropdown}>
                Homeowners ▾
                {isHomeownersDropdownOpen && (
                  <div className="dropdown-content">
                    <a href="https://aepm.cincwebaxis.com/account/loginmodernthemes" target="_blank" rel="noopener noreferrer" onClick={handleExternalLinkClick}>
                      Manage My Account
                    </a>
                    <a href="https://aepm.cincwebaxis.com/" target="_blank" rel="noopener noreferrer" onClick={handleExternalLinkClick}>
                      Make A Payment
                    </a>
                  </div>
                )}
              </div>
              <div className="links-dropdown" onMouseEnter={toggleClientsDropdown} onMouseLeave={toggleClientsDropdown}>
                Clients ▾
                {isClientsDropdownOpen && (
                  <div className="dropdown-content">
                    <a href="https://www.homewisedocs.com/nxlapp/appres/index.jsp" target="_blank" rel="noopener noreferrer" onClick={handleExternalLinkClick}>Closing Request</a>
                    <a href="https://www.homewisedocs.com/nxlapp/appres/index.xhtml" target="_blank" rel="noopener noreferrer" onClick={handleExternalLinkClick}>Association Documentation</a>
                    <NavLink to="/home-wise-docs" className={getActiveClassName} onClick={handleExternalLinkClick}>Why Homewise Docs?</NavLink>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
