import React from 'react';
import '../styles/App.css';
import HeroSection from './HeroSection';
import WelcomeSection from './WelcomeSection';
import ServicesSection from './ServicesSection';

const HomeSection: React.FC = () => {
  return (
    <div>
        <HeroSection />
        <WelcomeSection />
        <ServicesSection />
    </div>
  );
}

export default HomeSection;