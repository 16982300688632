import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const RequestAProposal = () => {
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData = {
      submissionType: 'RequestAProposal',
      associationName: event.target.elements.associationName.value,
      associationAddress: event.target.elements.associationAddress.value,
      city: event.target.elements.city.value,
      zipCode: event.target.elements.zipCode.value,
      numberOfUnits: event.target.elements.numberOfUnits.value,
      plannedNumberOfUnits: event.target.elements.plannedNumberOfUnits.value,
      typeOfConstruction: event.target.elements.typeOfConstruction.value,
      typeOfProperty: event.target.elements.typeOfProperty.value,
      pud: event.target.pud.value,
      amenities: event.target.elements.amenities.value,
      currentManagementCompany: event.target.elements.currentManagementCompany.value,
      annualMeetingMonth: event.target.elements.annualMeetingMonth.value,
      boardMeetingTime: event.target.elements.boardMeetingTime.value,
      lawFirmName: event.target.elements.lawFirmName.value,
      requesterName: event.target.elements.requesterName.value,
      boardTitle: event.target.elements.boardTitle.value,
      email: event.target.elements.email.value,
      phone: event.target.elements.phone.value,
      message: event.target.elements.message.value,
      recaptchaValue: recaptchaValue
    };

    console.log('Form Data:', formData);

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Thank you for your inquiry. We will get back to you as soon as possible.');
        console.log('Form submitted:', result);
        event.target.reset();  // Reset the form fields
        setRecaptchaValue(''); // Reset the ReCAPTCHA
      } else {
        alert('There was an error submitting your inquiry. Please try again later.');
        console.log('Form submission failed:', result);
        event.target.reset();  // Reset the form fields
        setRecaptchaValue(''); // Reset the ReCAPTCHA
      }
    } catch (error) {
      alert('There was an error submitting your inquiry. Please try again later.');
      console.error('Error submitting form:', error);
      event.target.reset();  // Reset the form fields
      setRecaptchaValue(''); // Reset the ReCAPTCHA
    }
  };

  return (
    <div className='card'>
      <h1>Request A Proposal</h1>
      <p style={{fontStyle: 'italic', textAlign: 'center'}}>If you would like to find out how AEPM can assist your Association, request a customized proposal by completing this form.</p>
      
      <form onSubmit={handleSubmit}>
        <h2>Property Information</h2>
        <input type="text" name="associationName" placeholder="Name of Association" required />
        <input type="text" name="associationAddress" placeholder="Association Address" required />
        <input type="text" name="city" placeholder="City" required />
        <input type="text" name="zipCode" placeholder="Zip Code" required />
        <input type="text" name="numberOfUnits" placeholder="Number of Units" />
        <input type="text" name="plannedNumberOfUnits" placeholder="Planned Number of Units" />
        <input type="text" name="typeOfConstruction" placeholder="Type of Construction" />
        <select name="typeOfProperty" required>
          <option value="">Type of Property</option>
          <option value="Condominium">Condominium</option>
          <option value="Townhome">Townhome</option>
          <option value="Single Family">Single Family</option>
        </select>
        <div style={{ display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center' }}>
          <label>Planned Unit Development?</label>
          <input type="radio" name="pud" value="Yes" /> Yes
          <input type="radio" name="pud" value="No" /> No
        </div>
        <input type="text" name="amenities" placeholder="Amenities" />
        <input type="text" name="currentManagementCompany" placeholder="Current Management Company" />
        <select name="annualMeetingMonth" required>
          <option value="">Annual Meeting Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
        <input type="text" name="boardMeetingTime" placeholder="When Board Meeting Is Held" />
        <input type="text" name="lawFirmName" placeholder="Name of Law Firm For Association" />

        <h2>Contact Information</h2>
        <input type="text" name="requesterName" placeholder="Name of Person Making Request" required />
        <input type="text" name="boardTitle" placeholder="Your Board Title" required />
        <input type="email" name="email" placeholder="Your Email Address" required />
        <input type="tel" name="phone" placeholder="Your Phone Number" required />
        <textarea name="message" placeholder="Message"></textarea>

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={handleRecaptchaChange}
          style={{ marginTop: '20px' }}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default RequestAProposal;
