import React from 'react';

const FinancialServices = () => {
  return (
    <div className='card'>
      <h1>
        Financial Services
        <img src='/financial_services.jpg' alt='Financial Services' className='card-img-top' />
      </h1>
      <p>Our experienced professionals are equipped to handle all of your accounting and financial needs.</p>

      <h2>Atlanta Executive Property Management (AEPM)</h2>
      <p>Providing comprehensive financial services is our top priority at Atlanta Executive Property Management (AEPM). We will provide complete accounting and financial services.</p>

      <h3>Our Financial Services Include:</h3>
      <ul>
        <div className='column'>
            <li>Preparation of Monthly Financial Statements</li>
            <li>Preparation of Bank Reconciliations</li>
            <li>Preparation of Annual Income Tax Returns</li>
            <li>Preparation of Annual Budgets</li>
            <li>Maintaining Bank Signature Cards</li>
            <li>Preparation of Association Records for Annual Audits</li>
            <li>Preparation of Special Reports</li>
        </div>
        <div className='column'>
            <li>Working with Attorneys for Collection Activities</li>
            <li>Responding to Client Account Questions</li>
            <li>Processing Homeowner Assessments and Fines</li>
            <li>Depositing Association Checks</li>
            <li>Assist homeowner associations with credit card payments</li>
            <li>Maintenance of Individual Account Ledgers</li>
        </div>
      </ul>

      <h3>Testimonial</h3>
      <blockquote>
        "I served on my HOA Board for a number of years. After working with several property management companies over the years, I found that Atlanta Executive Property Management was the best fit for our neighborhood." - Patrick H., HOA Board Member
      </blockquote>
    </div>
  );
};

export default FinancialServices;
