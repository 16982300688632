import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const About = () => {
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    const formData = {
      submissionType: 'About',
      associationName: event.target.elements.associationName.value,
      address: event.target.elements.address.value,
      city: event.target.elements.city.value,
      zipCode: event.target.elements.zipCode.value,
      numberOfUnits: event.target.elements.numberOfUnits.value,
      yourName: event.target.elements.yourName.value,
      yourBoardTitle: event.target.elements.yourBoardTitle.value,
      email: event.target.elements.email.value,
      phone: event.target.elements.phone.value,
      message: event.target.elements.message.value,
      recaptchaValue: recaptchaValue
    };

    console.log('Form Data:', formData);

    try {
        const response = await fetch('/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        const result = await response.json();
        if (response.ok) {
          alert('Thank you for your inquiry. We will get back to you as soon as possible.');
          console.log('Form submitted:', result);
          event.target.reset();  // Reset the form fields
          setRecaptchaValue(''); // Reset the ReCAPTCHA
        } else {
          alert('There was an error submitting your inquiry. Please try again later.');
          console.log('Form submission failed:', result);
          event.target.reset();  // Reset the form fields
          setRecaptchaValue(''); // Reset the ReCAPTCHA
        }
    } catch (error) {
        alert('There was an error submitting your inquiry. Please try again later.');
        console.error('Error submitting form:', error);
        event.target.reset();  // Reset the form fields
        setRecaptchaValue(''); // Reset the ReCAPTCHA
    }
 };

  return (
    <div className='card' style={{display: 'flex'}}>
        <div className='container' style={{marginRight: '2em'}}>
            <h1>About Us</h1>
            <p>
                <img src='/about_aepm.jpg' alt='About Us' style={{ width: '100%', float: 'left', marginBottom: '1em' }} />
                There are many Homeowner Association Management companies that your community can choose from; however, what makes
                Atlanta Executive Property Management (AEPM) unique is that we pride ourselves on our “hands-on team approach.”
            </p>

            <p>We believe proper and efficient management coupled with working together will help to make the best of your community. That’s why it is critical to choose a management company with the integrity and experience to help you make the best of your investment. With over 20 years of experience, AEPM works closely with you and your Board of Directors to accomplish this goal.</p>

            <div className='container'>
                <h2>Our Principles</h2>
                <ul style={{display: 'block' }}>
                    <li>We believe that family comes first, and strong, supportive communities help provide safe environments for all families.</li>
                    <li>We have a genuine concern for communities and are proud to be “ambassadors” for your community and association.</li>
                    <li>AEPM has experienced Homeowners Association staff that works to understand your concerns.</li>
                </ul>
            </div>
            <div className='container'>

                <h2>Our Services</h2>
                <ul style={{display: 'block' }}>
                    <li>We provide accounting services that include the collection of homeowners dues and timely payment of invoices, using cutting-edge technology for efficient services.</li>
                    <li>We are available to assist in the resolution of your challenges or concerns.</li>
                    <li>Member(s) of our staff will attend owners meetings and distribute reports to the board.</li>
                    <li>We provide oversight of Association contractors and vendors.</li>
                    <li>We generate HOA statements/reports to state agencies as required.</li>
                    <li>We are one of the premiere companies in Atlanta with a reputation of “turning communities around” and being “a company that will go to bat for you.”</li>
                </ul>
            </div>
        </div>
        
        <form onSubmit={handleSubmit}>
            <h1 style={{textAlign: 'center'}}>Inquire About Our Services<br/>678-235-2270</h1>
            <h2>Property Information</h2>
            <input type="text" name="associationName" placeholder="Name of Association" required />
            <input type="text" name="address" placeholder="Address" required />
            <input type="text" name="city" placeholder="City" required />
            <input type="text" name="zipCode" placeholder="Zip Code" required />
            <input type="text" name="numberOfUnits" placeholder="Number of Units" />

            <h2>Contact Information</h2>
            <input type="text" name="yourName" placeholder="Your Name" required />
            <input type="text" name="yourBoardTitle" placeholder="Your Board Title" required />
            <input type="email" name="email" placeholder="Your Email Address" required />
            <input type="tel" name="phone" placeholder="Your Phone Number" required />
            <textarea name="message" placeholder="Message"></textarea>
            <br/>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                onChange={handleRecaptchaChange}
            />
            <button style={{margin: '1em 0 0 0'}} type="submit">Submit</button>
        </form>
    </div>
  );
};

export default About;
