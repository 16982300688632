import React from 'react';

const RealEstateServices = () => {
  return (
    <div className='card'>
      <h1>
        Real Estate Services
        <img src='/clubhouse-pool-300x200.jpg' alt='Real Estate Services' style={{ width: '45%' }} />
      </h1>
      <p>Offering an array of real estate services based on your needs.</p>

      <h2>Atlanta Executive Property Management (AEPM)</h2>
      <p>
        AEPM offers an array of services based on the client's needs.
        Our services range from Title and Bankruptcy searches to Closing letters and Payoff requests.
        We understand that these intricate tasks are essential to your organization.
      </p>

      <h3>Our Services Include:</h3>
      <ul style={{justifyContent: 'left' }}>
        <div className='column'>
            <li>Title Searches</li>
            <li>Verify Ownership Information</li>
            <li>Bankruptcy Searches</li>
            <li>Monitor Post-Petition Payments Quarterly</li>
            <li>Closing Letters and Payoff Requests</li>
            <li>Proof Of Claim Filings</li>
        </div>
      </ul>

      <h3>Community Stewardship</h3>
      <p>We believe in helping to develop strong and safe communities. We accomplish this by supporting local causes and organizations. We also believe in developing and training steward leadership in the next generation by occasionally offering internship opportunities.</p>

      <h3>Mission</h3>
      <p>Our mission is to help build and empower communities where residents play an integral part of the team.</p>

      <h3>Testimonial</h3>
      <blockquote>
        "AEPM has been very responsive for our subdivision. AEPM provides timely responses to our homeowners and handles vendor issues effectively." - Douglas T, President, POA
      </blockquote>
    </div>
  );
};

export default RealEstateServices;
