import React from 'react';
import '../styles/WelcomeSection.css';

const WelcomeSection: React.FC = () => {
  return (
    <div className="welcome-section">
      <div className='welcome-content'>
        <h2>Welcome to Atlanta Executive Property Management</h2>
        <p><span className='italic'>Our mission is to help build and empower communities where residents play an integral part of the team.</span><br/><br/>
        We believe proper and efficient management coupled with working together will help to make the best of your community.
        That’s why it is critical to choose a management company with the integrity and experience to help you make the best of your investment.
        With over 20 years of experience, AEPM works closely with you and your Board of Directors to accomplish this goal.</p>
        <a href="/about-us"><button>Read More About Us</button></a>
      </div>
      <div className='testimonial-content'>
        <h2>From Our Clients</h2><hr/>
        <p style={{fontStyle: 'italic'}}>“After working with several property management companies over the years, I found that Atlanta Executive Property Management was the best fit for our neighborhood.  They not only exhibited a very professional attitude, but they seemed to take a genuine interest in ensuring that our community thrived.”<br/><br/>
        ~ Patrick H., HOA Board Member</p>
      </div>
    </div>
  );
}

export default WelcomeSection;
