import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFoundPage.css';

const NotFoundPage: React.FC = () => {
  return (
    <div className="card">
      <div className="not-found">
        <h1>404</h1>
        <p>Page Not Found</p>
        <Link to="/">Go Home</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
