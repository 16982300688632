import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import '../styles/HeroSection.css';

const HeroSection: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div className="hero-section">
      <Slider {...settings}>
        <div className="slide" >
          <img src="/city_of_atlanta.jpg" alt="City of Atlanta" />
        </div>
        <div className="slide">
          <img src="/neighborhood1.png" alt="Community Management Services" />
          <div className="slide-content">
            <h2>Community Management Services</h2>
            <p>Our comprehensive approach offers everything your association needs to ensure the value and continuity of your community and the investment of its residents.</p>
            <Link to="/services/community-management"><button>Learn More</button></Link>
          </div>
        </div>
        <div className="slide">
          <img src="/finance-bg.jpg" alt="Financial Services" />
          <div className="slide-content">
            <h2>Financial Services</h2>
            <p>Providing comprehensive financial services is our top priority. Complete accounting and financial services tailored to your needs.</p>
            <Link to="/services/financial-services"><button>Learn More</button></Link>
          </div>
        </div>
        <div className="slide">
          <img src="/clubhouse-pool.jpg" alt="Real Estate Services" />
          <div className="slide-content">
            <h2>Real Estate Services</h2>
            <p>Our services range from Title and Bankruptcy searches to Closing letters and Payoff requests, catering to all your real estate needs.</p>
            <Link to="/services/real-estate-services"><button>Learn More</button></Link>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HeroSection;
