import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Rental = () => {
    const [recaptchaValue, setRecaptchaValue] = useState('');

    const handleRecaptchaChange = (value: any) => {
        setRecaptchaValue(value);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = {
            submissionType: 'Rental',
            name: event.target.elements.name.value,
            address: event.target.elements.address.value,
            email: event.target.elements.email.value,
            phone: event.target.elements.phone.value,
            inquiryType: event.target.elements.inquiryType.value,
            comment: event.target.elements.comment.value,
            recaptchaValue: recaptchaValue
        };

        try {
            const response = await fetch('/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (response.ok) {
                alert('Thank you for your inquiry. We will get back to you as soon as possible.');
                console.log('Form submitted:', result);
                event.target.reset();  // Reset the form fields
                setRecaptchaValue(''); // Reset the ReCAPTCHA
            } else {
                alert('There was an error submitting your inquiry. Please try again later.');
                console.log('Form submission failed:', result);
                event.target.reset();  // Reset the form fields
                setRecaptchaValue(''); // Reset the ReCAPTCHA
            }
        } catch (error) {
            alert('There was an error submitting your inquiry. Please try again later.');
            console.error('Error submitting form:', error);
            event.target.reset();  // Reset the form fields
            setRecaptchaValue(''); // Reset the ReCAPTCHA
        }
    };

    return (
        <div className='card' style={{ display: 'flex' }}>
            <div className='container' style={{ marginRight: '1em', width: '100%' }}>
                <h1>Rental</h1>
                <img src="/rental.jpg" alt="Single-family and Short-term rentals" style={{ float: 'none', width: '90%' }} />
                <h2>Single-family and Short-term rentals</h2>
                <p>Atlanta Executive Property Management is your complete resource for property management services. Whether your home is a single-family residence or a short-term rental, you can rely on AEPM’s professional expertise. We can help with it all, from advertising, leasing, tenant screening, and maintenance issues to financial reporting with complete turnkey operations. Our team of experienced staff will handle it for you so you can focus on your daily life. Contact us today to see how AEPM can help you manage your properties.</p>
            </div>

            <form onSubmit={handleSubmit}>
                <h2>Inquire About Rental Services</h2>
                <input type="text" name="name" placeholder="Name" required />
                <input type="text" name="address" placeholder="Address" />
                <input type="email" name="email" placeholder="Email" required />
                <input type="tel" name="phone" placeholder="Phone" />
                <select name="inquiryType">
                    <option value="generalInquiry">General Inquiry</option>
                    <option value="vendorRequest">Vendor Request</option>
                    <option value="maintenanceRequest">Maintenance Request</option>
                    <option value="clubhouseAvailability">Clubhouse Availability</option>
                    <option value="associationManagement">Association Management</option>
                    <option value="singleFamilyRental">Single Family/Short Term Rental Management</option>
                    <option value="other">Other</option>
                </select>
                <textarea name="comment" placeholder="Comment"></textarea>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                    onChange={handleRecaptchaChange}
                    style={{ marginTop: '20px' }}
                />
                <button type="submit" style={{ marginTop: '20px' }}>Submit</button>
            </form>
        </div>
    );
};

export default Rental;
