import React from 'react';

const CommunityManagement = () => {
  return (
    <div className='card'>
      <h1>Community Management</h1>
      <h2>
        We can help with all your property management needs.
        <img src='/community_mgmt.jpg' alt='Community Management' />
      </h2>

      <p>Atlanta Executive Property Management (<b>AEPM</b>) is one of the area’s elite homeowners, condominium, and townhome association management firms. Our comprehensive approach and management services offer everything your association needs to ensure the value and continuity of your community and the investment of its residents.</p>

      <p>We’re dedicated to fostering vibrant and competent community associations from governance to grounds maintenance. Our primary focus and priority is to support the needs of the community. Atlanta Executive Property Management’s primary focus and priority is to support the needs and meet the goals of its community owners. We strive to exceed the expectations of the community by delivering quality services.</p>

      <p>We customize our services to address the specific needs of each client. Clients are interviewed to gain a better understanding of their particular need.</p>

      <h3>Our Services</h3>
      <ul>
        <div className='column'>
            <li>Respond to Homeowner and Vendor calls</li>
            <li>Attend Board of Directors meetings</li>
            <li>Supply Information to Committees regarding their functions</li>
            <li>Attend Association Annual Meetings</li>
            <li>Provide Orientation Materials for new Board of Directors</li>
            <li>Prepare property inspection reports</li>
            <li>Prepare Violation and Hearing Notices</li>
            <li>Set up New Association Accounts</li>
            <li>Assist in Preparing Bid Specifications</li>
            <li>Act as Community Liaison</li>
            <li>Obtain appropriate Insurance for HOA</li>
        </div>
        <div className='column'>
            <li>Review Vendor Contracts</li>
            <li>Perform Regular Property Inspections</li>
            <li>Initiate Repair and Maintenance work with Vendors</li>
            <li>Identify Architectural and Maintenance Violations</li>
            <li>Code and Approve association Invoices</li>
            <li>Review Monthly Association Financial Statements</li>
            <li>Identify Budget Variances</li>
            <li>Assist in Annual Budget Preparations</li>
            <li>Assist in Reserve Fund Management</li>
            <li>Process Mailings to Homeowners</li>
            <li>Process Change of Ownership Requests</li>
        </div>
      </ul>

      <h3>Testimonial</h3>
      <blockquote>
        "In my experience, as both a homeowner and board member, Atlanta Executive Property Management (AEPM) does an exceptional job overseeing the management of our subdivision HOA." - Chanteasea S., HOA Board Member
      </blockquote>
    </div>
  );
};

export default CommunityManagement;
