import React from 'react';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <p className="footer-text">
            <img src="/logo-300x165.png" alt="logo-small" className="footer-logo" width={147} />
            Atlanta Executive Property Management is one of metro Atlanta’s elite homeowners, condominium, and town home association management firms.
            We offer an array of community management, financial and real estate services to meet our client’s needs.
          </p>
        </div>
        <div className="footer-section">
          <h4 className="footer-title">Important Links</h4>
          <ul className="footer-links">
            <li><a href="https://aepm.cincwebaxis.com/account/loginmodernthemes" target="_blank" rel="noreferrer">Manage My Account</a></li>
            <li><a href="https://aepm.cincwebaxis.com/" target="_blank" rel="noreferrer">Make A Payment</a></li>
            <li><a href="https://www.homewisedocs.com/nxlapp/appres/index.jsp" target="_blank" rel="noreferrer">Closing Request</a></li>
            <li><a href="https://www.homewisedocs.com/nxlapp/appres/index.xhtml" target="_blank" rel="noreferrer">Association Documentation</a></li>
            <li><a href="/home-wise-docs">Why Homewise Docs?</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <p className="footer-title">Affiliations</p>
          <a href="https://www.caionline.org/" target="_blank" rel="noreferrer"><img className='affiliate-logo' src="/cai_logo-1.gif" alt="CAI Logo" /></a>
          <a href="http://www.grec.state.ga.us/" target="_blank" rel="noreferrer"><img className='affiliate-logo' src="/georgia-hoa-alliance-logo-100x87.jpg" alt="Georgia HOA Alliance Logo" /></a>
          <a href="http://georgiahoaalliance.org/" target="_blank" rel="noreferrer"><img className='affiliate-logo' src="/grec-symbol-100x100.jpg" alt="GREC Symbol" /></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Atlanta Executive Property Management. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
