import React from 'react';
import '../styles/HomeWiseDocs.css';

const HomeWiseDocsSection: React.FC = () => {
  return (
    <section className="home-wise-docs-section">
        <img src="/home-wise-docs-logo.png" alt="Home Wise Docs" className="home-wise-docs-image" />
        <div className="content">
            <p>
            Atlanta Executive Property Management (AEPM) has partnered with&nbsp;
            <a href="https://www.homewisedocs.com/" target="_blank" rel="noopener noreferrer">HomeWiseDocs.com</a>, 
            the industry’s leading community association escrow, closing and document service company, to offer a streamlined 
            process for the order and delivery of community association resale and lender documents. Professionals requiring 
            essential real estate data and documentation for association real estate transactions can find it with&nbsp;
            <a href="https://www.homewisedocs.com/" target="_blank" rel="noopener noreferrer">HomeWiseDocs.com</a>.
            Sign up is quick and easy and you can begin using the system immediately. Resale Disclosures, Estoppels, 
            Demands, Lender Questionnaires, and Association Governing Documents are all available with HomeWiseDocs.com. 
            Their professional staff is available for any questions you may have toll-free at <b>866-925-5004 x 1 or use our Online Chat.</b>
            </p>
            <p>
            Realtors and Title Companies <a href="https://www.homewisedocs.com/" target="_blank" rel="noopener noreferrer">click here for HOA Documents and Questionnaires</a>
            </p>
            <p>
            AEPM provides escrow services for those homeowners going through a sale or refinance. All services provided, 
            unless otherwise noted, require an upfront fee.
            </p>
            {/* Repeat the structure above for other paragraphs and bullet points */}
            <p>
            <strong>Note:</strong> AEPM does not complete any third party certification, warranty, or questionnaire forms.
            </p>
            <p>
            <strong>Demand/Statement of Account.</strong> AEPM’s office provides a demand, typically toward the end of an 
            escrow transaction, which reflects all debts and violations on a specific property. Demands are now provided 
            through&nbsp;<a href="https://www.homewisedocs.com/" target="_blank" rel="noopener noreferrer">www.homewisedocs.com</a>. 
            However, in order to process a demand request, AEPM’s office must receive written authorization from the seller/current 
            owner. Until the authorization is received, the demand will not be processed, including those for bank owned properties. 
            Demands will be processed within (XX) days of receiving a complete request. However, by paying the applicable rush fee, 
            the demand will be ready by the end of the next business day.
            </p>
            <p>
            <strong>Note:</strong> All demands are done first come, first serve, except in the case of a rush. Demands will not 
            be taken via fax, phone or email.
            </p>
            <p>
            <strong>Closing Requests</strong>
            </p>
            <p>
            AEPM has partnered with HomeWiseDocs.com to provide 24/7 document and data delivery services for community associations. 
            HomeWiseDocs.com provides reliable, around-the-clock online access to all governing documents and critical project data 
            for lenders, escrow and title companies, real estate agents and homeowners from AEPM communities.Log on to 
            <a href="https://www.homewisedocs.com/" target="_blank" rel="noopener noreferrer">www.homewisedocs.com</a> and select 
            the link to register. The many system enhancements geared toward an improved user experience include:
            </p>
            <ul>
            <li>Order documents by address or association name searches</li>
            <li>Share your order with up to four email addresses</li>
            <li>Multiple payment methods offered</li>
            <li>Hard copy delivery options available</li>
            <li>Email and SMS text completion notices for users</li>
            <li>Rush order requests</li>
            <li>Track your orders online with order confirmation number</li>
            </ul>
            <p>
            AEPM will continue to bring the latest tools and practices to the community association industry and affiliated professionals. 
            HomeWiseDocs.com simplifies the process of obtaining the association information that you require.
            </p>
        </div>
    </section>
  );
};

export default HomeWiseDocsSection;
