import React from 'react';
import '../styles/ServicesSection.css';

const ServicesSection: React.FC = () => {
  return (
    <div className="services-section">
      <h3 className="services-title">Services We Offer</h3>
      <p className="services-subtitle">
      We believe proper and efficient management coupled with working together will help to make the best of your community.
      That’s why it is critical to choose a management company with the integrity and experience to help you make the best of your investment.
      With over 20 years of experience, AEPM works closely with you and your Board of Directors to accomplish this goal.
      We provide the following comprehensive services:<br/><br/>
      </p>
      <div className="service">
        <a href="/services/community-management">Community Management</a>
        <p>Comprehensive approach to ensure the value and continuity of your community.</p>
      </div>
      <div className="service">
        <a href="/services/financial-services">Financial Services</a>
        <p>Complete accounting and financial services, prioritizing comprehensive support.</p>
      </div>
      <div className="service">
        <a href="/services/real-estate-services">Real Estate Services</a>
        <p>Range of services from Title and Bankruptcy searches to Closing letters and Payoff requests.</p>
      </div>
      <p className="services-subtitle">
        If you have a need for a service not listed here, <a href="/contact" className='just-ask'>just ask us about it.</a>
        &nbsp;We’ll be happy to provide a customized solution or package of services tailored to the specific needs of your Association. 
      </p>
    </div>
  );
}

export default ServicesSection;
